import React, { Component } from 'react'
import { FaArrowRight } from 'react-icons/fa'

export default class TableItem extends Component {
  handleClick = link => {
    window.open(link)
  }

  render() {
    const { title, category, description, link, date } = this.props

    return (
      <tr className="resources-row" onClick={() => this.handleClick(link)}>
        <td width="15%">{date}</td>
        <td width="20%">{title}</td>
        <td width="15%">{category}</td>
        <td>{description}</td>
        <td>
          <FaArrowRight />
        </td>
      </tr>
    )
  }
}
