import React, { Component } from 'react'
import TableItem from '../components/resource/tableItem'
import Footer from '../components/footer'
import Layout from '../layouts/index'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

class Resources extends Component {
  renderList = resources => {
    return resources.map(resource => (
      <TableItem
        key={resource.link}
        date={resource.date}
        category={resource.category}
        title={resource.title}
        description={resource.description}
        link={resource.link}
        item={resource.item}
      />
    ))
  }

  render() {
    // const { resources } = this.props.data.allDataJson.edges[1].node

    let resources = ''

    if (this.props.data.allDataJson.edges[1].node.resources == null) {
      resources = this.props.data.allDataJson.edges[0].node.resources
    } else {
      resources = this.props.data.allDataJson.edges[1].node.resources
    }

    resources = resources.sort((a, b) => {
      return new Date(b.isoDate) - new Date(a.isoDate)
    })

    return (
      <Layout>
        <Helmet>
          <meta
            name="description"
            content="List of useful web development tutorials, articles, tools, websites and Apps for frontend developer and entrepreneur."
          />
        </Helmet>
        <div className="container">
          <div className="resources">
            <section className="section section-title">
              <h1 className="title has-text-centered">
                Useful tutorials, articles, websites and Apps I discovered.
              </h1>
              <hr className="section-title__gap" />
            </section>

            <div className=" resources-table">
              <table className="table  is-hoverable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{resources && this.renderList(resources)}</tbody>
              </table>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query Resources {
    allDataJson {
      edges {
        node {
          resources {
            date
            category
            title
            description
            link
            item
            isoDate
          }
        }
      }
    }
  }
`

export default Resources
